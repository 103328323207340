import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BigButton from '../common/big-button';
import PageColumn from '../layout/page-column';
import RainbowBar from '../common/rainbow-bar';
import Section from '../layout/section';
import SectionHeader from '../common/section-header';
import TaxonomyGrid from '../common/taxonomy/grid';

export default (props) => {
  const data = useStaticQuery(graphql`
    query CategoriesQuery {
      categories:allAirtable(filter:{table:{eq:"Categories"}},
                      sort:{fields: data___Label, order:ASC}) {
        edges {
          node {
            data {
              Label
              Slug
              Books {
                data {
                  Slug
                }
              }
            }
          }
        }
      }
    }
  `);
  let selectedCategoryNodes = data.categories.edges.map(({ node }) => node);
  if (props.excludeCategory) {
    selectedCategoryNodes = selectedCategoryNodes.filter(node => node.data.Slug !== props.excludeCategory);
  }
  selectedCategoryNodes = selectedCategoryNodes.sort((a, b) => {
    if (!a.data.Books) return 1;
    if (!b.data.Books) return -1;

    return b.data.Books.length - a.data.Books.length;
  }).slice(0, 9);

  return (
    <>
      <RainbowBar width='thin' />
      <Section topSpacing="section" color="light-gray">
        <PageColumn>
          <SectionHeader label="Top Categories" />
          <TaxonomyGrid
            nodes={ selectedCategoryNodes }
            labelProperty="Label"
            pathStem="/category"
          />
        </PageColumn>
      </Section>
      <Section topSpacing="subsection" bottomSpacing="section" color="light-gray">
        <PageColumn>
          <BigButton
            to="/categories"
            label="All Categories"
          />
        </PageColumn>
      </Section>
    </>
  );
}
