import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../../components/layout/base-layout';

import BookList from '../../components/books/list';
import CatalogHeader from '../../components/common/catalog-header';
import PageColumn from '../../components/layout/page-column';
import Pagination from '../../components/common/pagination';
import RainbowBar from '../../components/common/rainbow-bar';
import Section from '../../components/layout/section';
import TopCategoriesSection from '../../components/categories/top-categories-section';


export default (props) => {
  const start = (props.pageContext.page - 1) * props.pageContext.perPage;
  const categoryNode = props.data.category;
  const filteredBookNodes = props.data.category.data.Books
                                 .slice(start, start + props.pageContext.perPage);
  return (
    <BaseLayout>
      <Helmet>
        <title>Cookbooks in the { categoryNode.data.Label } Category</title>
        <meta
          property="og:title"
          content={ `Cookbooks in the ${ categoryNode.data.Label } Category` }
        />
      </Helmet>
      <CatalogHeader
        label={(
          <>
            Cookbooks in the <span className="highlight">{categoryNode.data.Label}</span> Category
          </>
        )}
      />
      <RainbowBar width={'thin'} />
      <Section topSpacing="section">
        <PageColumn>
          <BookList bookNodes={ filteredBookNodes } />
        </PageColumn>
      </Section>
      <Section topSpacing="section" bottomSpacing="section">
        <Pagination
          pathStem={`/category/${categoryNode.data.Slug}/`}
          page={props.pageContext.page}
          perPage={props.pageContext.perPage}
          totalPages={props.pageContext.totalPages}
        />
      </Section>
      <TopCategoriesSection />
    </BaseLayout>
  );
};

export const query = graphql`
query($slug:String) {
  category:airtable(table:{eq:"Categories"}, data: {Slug: {eq:$slug}}) {
    data {
      Slug
      Label
      Books {
        data {
          ...BookForListing
        }
      }
    }
  }
}
`;